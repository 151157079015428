.d-hidden{
    display: none;
}
.w-80{
    width: 80%;
}
.service{
    text-align: justify;
}
.single-slider {
    background-image: url('./img/slider/slider-1.png');
    background-position: center center;
}
.item-1{
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.item-2{
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-3{
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-4{
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slider__nav-item.item-1::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #003667;

}
.slider__nav-item.item-3::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #90bf8a;

}
.slider__nav-item.green-bg::after{
    background:#4f003b;

}
.titleform{
    color:#01347b;

}
@media  only screen and (max-width: 900px) {
    .content{
      margin-top: 70px !important;
      text-align: center;
    }
   .titleform{
       font-size: calc(1.275rem + .3vw);
   }
  }
  .swiper-wrapper{
      min-height: 130px;
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
      border-radius: 15px !important;
  }  
  .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root{
    border-radius: 15px !important;
      
  }
  .g-btn{
      border-radius: 15px;
  }
  .font-icon-check{
    width: 40px;
    height: 40px;
    line-height: 65px;
    border-radius: 50%;
    background: #01347b;
    margin-right: 10px;
    text-align: center;
  }
  .font-icon-check path{
    fill: #d1d700;
  }
  .MuiOutlinedInput-root{
    border-radius: 15px !important;
  }